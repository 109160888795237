import { inject } from '@angular/core';
import { Router } from '@angular/router';

import { map, Observable, take, withLatestFrom } from 'rxjs';

import { RouterStoreFacade } from '../../store/router/router-store.facade';
import { UserConfigsStoreFacade } from '../../store/user-configs/user-configs-store.facade';
import { UserRolesEnum } from '../enums/user-roles/user-roles.enum';

export const canAccessRoute = (): Observable<boolean> => {
  const router = inject(Router);
  const userConfigsStoreFacade = inject(UserConfigsStoreFacade);
  const routerFacade = inject(RouterStoreFacade);

  return userConfigsStoreFacade.userRights$.pipe(
    take(1),
    withLatestFrom(
      routerFacade.noRightFallbackRoute$,
      routerFacade.requiredRights$,
      routerFacade.oneNeededRights$),
    map((
      [ role, fallbackRoute, requiredRights, oneNeededRights ]:
      [UserRolesEnum | undefined, string, Array<UserRolesEnum>, Array<UserRolesEnum>]) => {

      if (!role || (role && [ UserRolesEnum.NONE, UserRolesEnum.MANY ].includes(role))) {
        return false;
      }

      const requiredRightsResult = userHasRequiredRights(role, requiredRights);
      const neededRightsResult = userHasNeededRights(role, oneNeededRights);
      if (!(requiredRightsResult && neededRightsResult)) {
        router.navigate([ fallbackRoute ]);
      }

      return requiredRightsResult && neededRightsResult;
    }));
};

// we need all rights of the given array
const userHasRequiredRights = (role: UserRolesEnum, requiredRights: Array<string>): boolean => {
  if (requiredRights === undefined || requiredRights.length === 0) {
    return true;
  }

  return requiredRights[0] === role;
};

// we need at least one right of the given array
const userHasNeededRights = (role: UserRolesEnum, oneNeeded: Array<UserRolesEnum>): boolean => {
  if (oneNeeded === undefined || oneNeeded.length === 0) {
    return true;
  }

  return oneNeeded.includes(role);
};
